<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" back="Convention.Badges.Types">

		<template v-if="$authorised('con/badges/access', 'write')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

		</template>

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised">

		<app-content-box title="Settings" icon="settings" :readonly="$authorised('con/badges/access', 'read')">

			<app-input-text label="Name" v-model="model.name" :validation="$v.model.name" placeholder="Enter name" :maxlength="32" />
			<app-input-toggle :toggle="model.days.enabled" :asString="true" :column="true" v-on:toggle="model.days.enabled = !model.days.enabled" label="Restrict Days" v-model="model.days.range" :validation="$v.model.days.range" :multiple="true" :options="daysOptions" />
			<app-input-text :toggle="model.limit.enabled" v-on:toggle="model.limit.enabled = !model.limit.enabled" label="Limit Quantity" v-model="model.limit.amount" :validation="$v.model.limit.amount" placeholder="Enter limit" />

		</app-content-box>

		<app-content-box title="Design" icon="design" :readonly="$authorised('con/badges/access', 'read')">

			<app-input-toggle label="Name Format" v-model="model.design.lines" :validation="$v.model.design.lines" :options="lineOptions" />
			<app-input-colour v-model="model.design.colour" :validation="$v.model.design.colour" label="Color" placeholder="Enter hex" />
			<app-input-text :label="($convention.settings.badges.stacking.enabled) ? 'Full Label' : 'Label'" v-model="model.design.label" :validation="$v.model.design.label" placeholder="Enter label" :maxlength="32" />
			<app-input-text v-if="$convention.settings.badges.stacking.enabled" label="Stacked Label" v-model="model.design.stacked" :validation="$v.model.design.stacked" placeholder="Enter stacked label" :maxlength="4" />
			<app-input-image :toggle="model.design.image.left.enabled" v-on:toggle="model.design.image.left.enabled = !model.design.image.left.enabled" v-model="model.design.image.left.file" :validation="$v.model.design.image.left.file" label="Left Image" />
			<app-input-image :toggle="model.design.image.center.enabled" v-on:toggle="model.design.image.center.enabled = !model.design.image.center.enabled" v-model="model.design.image.center.file" :validation="$v.model.design.image.center.file" label="Center Image" />
			<app-input-image :toggle="model.design.image.right.enabled" v-on:toggle="model.design.image.right.enabled = !model.design.image.right.enabled" v-model="model.design.image.right.file" :validation="$v.model.design.image.right.file" label="Right Image" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, requiredIf, maxLength, numeric } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/badges/access', 'read'),
			lineOptions: {
				1: 'One line',
				2: 'Two lines'
			},
			model: {
				id: false,
				name: '',
				limit: {
					enabled: false,
					amount: 0
				},
				days: {
					enabled: false,
					range: []
				},
				design: {
					colour: '',
					label: '',
					stacked: '',
					lines: 1,
					image: {
						left: {
							enabled: false,
							file: ''
						},
						center: {
							enabled: false,
							file: ''
						},
						right: {
							enabled: false,
							file: ''
						}
					}
				}
			}
		}

	},

	validations: {
		model: {
			name: {
				required,
				maxLength: maxLength(32)
			},
			limit: {
				enabled: {
					required
				},
				amount: {
					requiredIf: requiredIf(function() {

						return this.model.limit.enabled

					}),
					numeric
				}
			},
			days: {
				enabled: {
					required
				},
				range: {
					requiredIf: requiredIf(function() {

						return this.model.days.enabled

					})
				}
			},
			design: {
				colour: {
					required
				},
				label: {
					required,
					maxLength: maxLength(32)
				},
				image: {
					left: {
						required
					},
					center: {
						required
					},
					right: {
						required
					}
				}
			}
		}
	},

	computed: {

		daysOptions: function() {

			var options = {}
			var date

			for (var day = this.$convention.start; day <= this.$convention.end; day += 86400) {

				date = this.$moment.unix(day).utc()

				options[this.$_.keys(options).length + 1] = date.format('dddd, MMMM Do')

			}

			return options

		}

	}

}

</script>

<style scoped>

</style>